import CLOUD_SCEND_IMG from "../assets/images/approach/CloudScend.jpg"
import EMERGING_NETWORK_IMG from "../assets/images/approach/EmergingNetworks.jpg"

export const data = [
  {
    title: "CloudScend",
    description:
      "SAIC CloudScend is a family of solutions that helps federal agencies modernize and move workloads to the cloud. SAIC delivers CloudScend by way of specialists, tools and processes to accelerate delivery, maximize value and minimize risks. SAIC CloudScend encompasses three core solutions – namely CloudScend Explore, CloudScend Migrate and CloudScend Operate; and four Service Enhancements (Automate, Secure, Optimize, & Innovate).",
    image: CLOUD_SCEND_IMG,
    link: "https://saiconline.sharepoint.us/teams/CloudScend2/SitePages/Solutions.aspx#cloudscend",
    subLinkTitle: "",
    subLink: (
      <a
        href=""
        target="_blank"
      >
      </a>
    ),
    subLink2Title: "",
    subLink2: "",
    subLink3Title: "",
    subLink3: "",
  },
  {
    title: "CloudScend Edge",
    description:
      "SAIC's Edge solution incorporates best of breed solutions from IoT, ruggedized edge compute, and a holistic and tailored approach to data transport (networking), data management, and DevSecOps orchestration at the Edge. SAIC’s body wearable edge compute solution (“Squire”) represents a radical advance in capability at the tactical edge.",
    image: EMERGING_NETWORK_IMG,
    link: "https://saiconline.sharepoint.us/teams/CloudScend2/SitePages/Solutions.aspx#cloudscend-edge",
    subLinkTitle: "",
    subLink: <a></a>,
    subLink2Title: "",
    subLink2: "",
    subLink3Title: "",
    subLink3: "",
  }
]
