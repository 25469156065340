import { Typography } from "@material-ui/core"
import faq from '../data/faq.js'
import React from "react"
import AccordionComponent from "../components/accordion"

export default function faqContent(props) {
  return (
    <div className="faqContentContainer">
      <div className="titleLine" />
      <Typography className="category">Operations</Typography>
      <div className="titleLine" />
      <div className="spacer" />
      <div className="accordionContainer">
        {props.content.map((data) => {
          if (data.category === "operations") {
            return (
              <AccordionComponent
                question={data.question}
                answer={data.answer}
                category={data.category}
                answer2={data.answer2}
                url={data.url}
              />
            );
          }
        })}
      </div>

      <div className="spacer" />

      <div className="titleLine" />
      <Typography className="category">Marketing</Typography>
      <div className="titleLine" />
      <div className="spacer" />
      <div className="accordionContainer">
        {props.content.map((data) => {
          if (data.category === "marketing") {
            return (
              <AccordionComponent
                question={data.question}
                answer={data.answer}
                category={data.category}
                answer2={data.answer2}
                url={data.url}
              />
            );
          }
        })}
      </div>

      <div className="spacer" />

    <div className="titleLine" />
    <Typography className="category">Technical Architecture and Engineering </Typography>
    <div className="titleLine" />
    <div className="spacer" />
    <div className="accordionContainer">
      {props.content.map((data) => {
        if (data.category === "technical") {
          return (
            <AccordionComponent
              question={data.question}
              answer={data.answer}
              category={data.category}
              answer2={data.answer2}
              url={data.url}
            />
          )
        }
      })}
    </div>


    </div>
  );
}
