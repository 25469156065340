import { Grid, Zoom, Fab } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Header from "./header";
import Footer from "./footer";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

function ScrollTop(props) {
  const { children, window } = props;
  const classes = useStyles();

  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      "#back-to-top-anchor"
    );

    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  return (
    <Zoom in={trigger}>
      <div
        onClick={handleClick}
        role="presentation"
        className={classes.root}
        style={{ float: "right", paddingRight: "5px" }}
      >
        {children}
      </div>
    </Zoom>
  );
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,

  window: PropTypes.func,
};

export default ({ children, ...props } = {}) => (
  <Grid container item {...props} className="appTemplate">
    <div id="back-to-top-anchor" />

    <Grid item xs={12} className="headerContainer">
      <Header />
    </Grid>

    <div className="pageSpacer" />

    <Grid item xs={12} className="container">
      {children}

      <ScrollTop {...props}>
        <Fab color="secondary" size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </Grid>

    <div className="spacer" />

    <Grid className="footerShape" item xs={12} sm={12} />

    <Grid item xs={12} className="footerContainer">
      <Footer />
    </Grid>
  </Grid>
);
