// import React from "react";
// import DrawerComponent from "../components/drawer";
// import { TrimWhiteSpace } from "../functions/utils";

// export default function NavBar() {
//   return (
//     <div className="navBar" >
//       <ul id="nav" className="desktopNav" >
//         <li>
//           <a href="/">
//           Home</a>
//         </li>

//         <li>
//           <a href="https://issaic.saic.com/" target="_blank" rel="noreferrer">
//           ISSAIC</a>
//         </li>

//         <li>
//           <a href="/offerings">
//           Offerings</a>
//         </li>

//         <li>
//           <a href={`/demos/` + TrimWhiteSpace("CloudScend101")}>
//           Demos</a>
//         </li>

//         <li>
//           <a href="https://digital.saicif.com/"> Digital</a>
//           <ul className="fadePanel" >
//             <li><a href="https://cyber.digital.saicif.com/">Cyber</a></li>
//             <li><a href="https://eit.digital.saicif.com/">EIT</a></li>
//             <li><a href="https://appdev.digital.saicif.com/">Software</a></li>
//           </ul>
//         </li>

//         <li>
//           <a href="/investments">
//           Investments</a>
//         </li>

//         <li>
//           <a href="/partnerRelations">
//           Partners</a>
//         </li>

//         <li>
//           <a href="/faqs">
//           FAQs</a>
//         </li>

//         <li>
//           <a href="/aboutUs">
//           About Us</a>
//         </li>
//       </ul>

//       <div className="mobileNavMenu">
//         <DrawerComponent />
//       </div>
//     </div>
//   );
// }

import React from 'react'
import DrawerComponent from '../components/drawer'
import {
  Grid,
   Table,
   TableRow,
   TableCell,
   TableContainer
} from '@material-ui/core'
import DropMenu from '../components/dropMenu'

import {TrimWhiteSpace} from '../functions/utils'

export default function NavBar() {

  return (
    <div className="navBar" >
      <TableContainer style={{boxShadow: 'none'}}>
    <Table className="desktopNav"    >
      <TableRow >
        <TableCell style={{borderBottom: 'none'}} >
            <a href="/" style={{color: '#fff', fontSize: '18px'}} >
                Home
              </a>
          
        </TableCell>
        <TableCell style={{borderBottom: 'none'}} >
            <a href="https://issaic.saic.com/sites/innovationfactory/overview" target="_blank" rel="noreferrer" style={{color: '#fff',fontSize: '18px'}} >
              ISSAIC
            </a>
        </TableCell>
        <TableCell style={{borderBottom: 'none'}} >
            <a href="/offerings" style={{color: '#fff',fontSize: '18px'}} >
              Offerings
            </a>
        </TableCell>
        <TableCell style={{borderBottom: 'none'}} >
            <a href={`/demos/` + TrimWhiteSpace("CloudScend101")} style={{color: '#fff',fontSize: '18px'}} >
              Demos
             </a>
        </TableCell>
        <TableCell style={{borderBottom: 'none'}} >
          <DropMenu
            menuItem={'Digital'}
            itemOne={'Cyber'}
            itemTwo={'App Dev'}
            itemThree={'EIT'}
            itemOneLink={'https://cyber.digital.saicif.com/'}
            itemTwoLink={'https://appdev.digital.saicif.com/'}
            itemThreeLink={'https://eit.digital.saicif.com/'}
          />
        </TableCell>
        <TableCell style={{borderBottom: 'none'}} >
              <a href="/partnerRelations" style={{color: '#fff',fontSize: '18px'}} >
                    Partners
              </a>
        </TableCell>

        <TableCell style={{borderBottom: 'none'}} >
              <a  href="/faqs" style={{color: '#fff',fontSize: '18px'}} >
                 FAQs
              </a>
        </TableCell>

        <TableCell style={{borderBottom: 'none'}} >
             <a  href="/aboutUs"  style={{color: '#fff',fontSize: '18px'}} >
                POCs
              </a>
        </TableCell>

      </TableRow>
    </Table>
    </TableContainer>


      <div className="mobileNavMenu">
        
        <DrawerComponent />
      </div>

  
    </div>

  )
}

