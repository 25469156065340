import React from "react";
import { Typography, Grid, Button } from "@material-ui/core";
import { DESC_ICON } from "../assets/icons/IconList";

export default function offeringsContent(props) {
  return (
    <div className="offeringsContentContainer">
      <Button
        href="https://saiconline.sharepoint.us/teams/CloudScend2/Shared%20Documents/Forms/AllItems.aspx?viewid=e7a6f5cf%2D04f0%2D40b3%2D8a77%2Dd1d0ed804db3"
        target="_blank"
        style={{
          fontSize: "18px",
          textAlign: "center",
          width: "325px",
          height: "50px",
          padding: "10px",
          color: "#ffffff",
          background: "#006bb6",
        }}
      >
        Document Repository
      </Button>
      
      <div className="spacer" />
      {props.content.map((data) => {
        return (
          <Grid container xs={12} spacing={3}>
            <Grid item xs={12}>
              <Typography variant="body1">{data.summary}</Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h5">{data.title}</Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body1">{data.description}</Typography>
            </Grid>
            <div className="spacer" />
            <div className="dividerShape" />
            <div className="spacer" />
          </Grid>
        );
      })}
    </div>
  );
}
