import TSwan from "../assets/images/profile/TSwan.png"
import BNelson from "../assets/images/profile/BNelson.png"
import CSchmid from "../assets/images/profile/CSchmid.png"
import DParrott from "../assets/images/profile/Donny.jpg"
import MLittlejohn from "../assets/images/profile/Matthew.jpg"

export const data = [
   {
    name: "Blake Nelson",
    title: "VP Innovation",
    email: "blake.nelson@saic.com",
    description: "Blake is a seasoned cloud solution evangelist and leader with over 20 years of experience driving innovative technical solutions and business transformation. Blake has extensive experience delivering successful outcomes for customers, including the adoption of hybrid and cloud-native capabilities, DevSecOps, big data analytics platforms, advanced analytics visualizations, and cloud-native capabilities:",
    image: BNelson,
  },
  {
    name: "Trevor Swanson",
    title: "Director, Platforms & Cloud Solutions and Support",
    email: "trevor.c.swanson@saic.com",
    description:
      "Trevor Swanson is the director of Platforms and Cloud Services and Support, responsible for sales enablement supporting the promotion of Platforms and Cloud solutions and offerings for digital platforms, data centers, edge computing, private and public cloud, multi-cloud, and platform security. Trevor is a results oriented individual with a wide breadth of proven expertise in challenging leadership, business, technical, and security roles with over 20 years of experience in IT infrastructure and management, including private cloud and public cloud environments. He has successfully spearheaded efforts in Cyber Security, Data Center deployment and operation, Orchestration and Automation, Private Cloud, and Multi-Cloud Management, delivering outstanding customer outcomes.",
    image: TSwan,
  },
  {
    name: "Carsten Schmid",
    title: "Platforms & Cloud Solutions Director",
    email: "carsten.r.schmid@saic.com",
    description: "",
    image: CSchmid,
  },
  {
    name: "Donny Parrott",
    title: "Platforms & Cloud Solutions Architectr",
    email: "donny.w.parrott@saic.com",
    description: "Solutions Architect focused on wholistic enterprise service delivery across the entire IT portfolio. Empowered customers to successfully achieve critical missions through automated distributed technologies. ",
    image: DParrott,
  },
  {
    name: "Matthew Littlejohn",
    title: "Platforms & Cloud Computer Engineer",
    email: "matthew.littlejohn@saic.com",
    description: "With over a decade of analytics consulting experience, Matthew Littlejohn is highly proficient with helping customers realize their opportunities in the cloud. Using his experience in ideation, R&D, and implementation, Mr. Littlejohn has provided award-winning solutions in public, private, and hybrid clouds, Edge/IoT systems, system monitoring and reporting, AI/ML Ops, and bespoke managed service solutions.",
    image: MLittlejohn,
  }
]


