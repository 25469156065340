import * as React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@material-ui/core";

import { EXPAND_MORE_ICON } from "../assets/icons/IconList";

export default function ControlledAccordions(props) {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          expandIcon={<EXPAND_MORE_ICON iconSize={30} iconColor={"#006bb6"} />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography variant="h5">
            <b>{props.question}</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>{props.answer}</Typography>
        </AccordionDetails>
        <AccordionDetails>
          <Typography>{props.answer2}</Typography>
        </AccordionDetails>
        <AccordionDetails>
          <Typography>{props.url}</Typography>
        </AccordionDetails>
      </Accordion>
      <br />
    </div>
  );
}
