import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { data } from "../data/approach";

export default function ApproachAI() {
  const [state] = React.useState({
    dataValues: data,
  });

  return (
    <div className="approachAIContainer">
 
      <Grid container xs={12} sm={10} md={8} lg={12} className="container marginCenter">
        {state.dataValues.map((item) => {
          return (
            <Grid item xs={11} sm={12} md={12} lg={6} className="columns" >
              <a
                href={item.link}
                className="linkBox"
                target="_blank"
                rel="noreferrer"
                alt={item.title}
              >
                <img src={item.image} style={{width: '60%'}} alt="Platfroms and CLoud Approach Image" />
                <div className="spacer" />
                <Typography className="title">{item.title}</Typography>
              </a>
              <div className="spacer" />
              <Typography>{item.description}</Typography>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}
