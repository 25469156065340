import React from "react";
import { Typography, Grid, Button } from "@material-ui/core";
import { TrimWhiteSpace } from "../functions/utils";
import Cloud101_Thumbnail_Poster from "../assets/images/thumbnails/Cloud101-Thumbnail.png";

import VideoData from "../data/videoCarousel.json";

export default function VideoDemoCarousel() {
  const [state] = React.useState({
    dataValues: VideoData,
  });

  return (
    <div className="videoDemoCarouselContainer">
      {state.dataValues.map((item) => {
        return (
          <Grid container xs={12}>
            <Grid item xs={12}>
              <Typography className="sectionTitle">
                Latest Demonstrations
              </Typography>
            </Grid>
            <div className="spacer" />

            <Grid item xs={12} sm={12} md={12} lg={8}>
              <video controls poster={Cloud101_Thumbnail_Poster}>
                <source src={item.src} />
              </video>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={4}
              className="sectionDescription"
            >
              <Typography component="h5" variant="h5" className="title">
                {item.title}
              </Typography>
              <div className="spacer" />
              <Typography className="text">{item.description}</Typography>

              <div className="spacer" />
              <div className="dividerShape" />
              <div className="spacer" />
              <Button
                href={`/demos/` + TrimWhiteSpace("CloudScend101")}
                className="button"
                variant="outlined"
              >
                More Videos
              </Button>
            </Grid>
          </Grid>
        );
      })}
    </div>
  );
}
