import React from "react";
import { Grid } from "@material-ui/core";
import AboutAI from "./content/aboutAI";
import ApproachAI from "./content/approachAI";
import VideoDemoCarousel from "./content/videoDemoCarousel";
import AwsExportConfig from "./aws-exports";
import { Auth, Amplify } from "aws-amplify";

Amplify.configure(AwsExportConfig());

class Home extends React.Component {
  componentDidMount() {
    const getUser = async () => {
      try {
        const authenticatedUser = await Auth.currentAuthenticatedUser();
        console.log("Home Page: ", authenticatedUser);
      } catch {
        console.log("The user isn't signed in");
        this.setState({
          isReloading: true,
        });

        setTimeout(() => {
          Auth.federatedSignIn({ customProvider: "SAIC" });
        }, 2000);
      }
    };

    getUser();
  }

  render() {
    return (
      <div className="fadePanel">
        <main style={{ flexGrow: "1" }} className="mainCotainer">
          <Grid container xs={12} sm={12} className="fadePanel">
            <Grid item xs={12} sm={12} className="marginCenter">
              <AboutAI />
            </Grid>

            <Grid item xs={12} sm={12} className="marginCenter">
              <ApproachAI />
            </Grid>

            <Grid item xs={12} sm={12} className="marginCenter">
              <VideoDemoCarousel />
            </Grid>
            
          </Grid>
        </main>
      </div>
    );
  }
}

export default Home;
