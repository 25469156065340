
import AWS from "../assets/images/partners/aws.jpg" 
import Cisco from "../assets/images/partners/cisco.png"
import DELLEMC from "../assets/images/partners/DellEmc.jpg"
import Microsoft from "../assets/images/partners/microsoft.jpg"
import NetApp from "../assets/images/partners/NetApp.jpg"
import Oracle from "../assets/images/partners/oracle.jpg"
import Redhat from "../assets/images/partners/redhat.jpg"
import Orca from "../assets/images/partners/Orca.png"
import Splunk from "../assets/images/partners/Splunk.png"
import VmWare from "../assets/images/partners/VmWare.jpg"
import GoogleCloud from "../assets/images/partners/cloudplatform.jpg"
import SalesForce from "../assets/images/partners/SalesForce.jpg"

export const data = [

  {
    title: "AWS",
    desc: "",
    image: AWS,
    category: "Strategic"
  },
  {
    title: "Cisco",
    desc: "",
    image: Cisco,
    category: "Strategic"
  },
  {
    title: "DELL EMC",
    desc: "",
    image: DELLEMC,
    category: "Strategic"
  },
  {
    title: "Microsoft",
    desc: "",
    image: Microsoft,
    category: "Strategic"
  },
  {
    title: "Net APP",
    desc: "",
    image: NetApp,
    category: "Strategic"
  },
  {
    title: "Oracle",
    desc: "",
    image: Oracle,
    category: "Strategic"
  },
  {
    title: "Redhat",
    desc: "",
    image: Redhat,
    category: "Strategic"
  },
  {
    title: "Orca",
    desc: "",
    image: Orca,
    category: "Strategic"
  },
  {
    title: "Splunk",
    desc: "",
    image: Splunk,
    category: "Strategic"
  },
  {
    title: "VM Ware",
    desc: "",
    image: VmWare,
    category: "Strategic"
  },
  {
    title: "Sales Force",
    desc: "",
    image: SalesForce,
    category: "Strategic"
  },
  {
    title: "Google Cloud Platform",
    desc: "",
    image: GoogleCloud,
    category: "Strategic"
  }

]
