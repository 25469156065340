// import React from "react";
// import { Grid, Typography } from "@material-ui/core"
// import { ProfileCard } from "../components/cards"

// import { data } from "../data/profileInfo"

// export default function AboutUsContent() {
//   const [state] = React.useState({
//     profileInfoData: data,
//   })

//   return (
//     <div className="aboutUsPageContainer">
//       <Grid container sm={12} spacing="3">
    
//           <Grid item sm={12}>
//             <div className="spacer" />
//             <Typography className="pageSubTitleSection">
//               Meet our Team
//             </Typography>
//           </Grid>


//         {state.profileInfoData.map((item) => {
//           return (
//             <Grid
//               container
//               xs={12}
//               sm={12}
//               md={11}
//               lg={11}
//               spacing={3}
//               className="profileContainer"
//             >
//                <Grid item xs={6} sm={6} md={6} lg={6}>
//                 <ProfileCard
//                   name={item.name + " profile image"}
//                   source={item.image}
//                 />
//               </Grid>

//               <Grid item xs={12} sm={12} md={6} lg={6} className="profileInfo">
//                 <Typography className="name">{item.name}</Typography>
//                 <Typography className="title">{item.title}</Typography>
//                 <Typography className="email">{item.email}</Typography>
//                 <div className="spacer" />
//                 <Typography variant="body1" className="desc">
//                   {item.description}
//                 </Typography>
//               </Grid>

             

//               <div className="spacer" />
//               <div className="dividerShape" />
//               <div className="spacer" />
//             </Grid>
//           );
//         })}

//         <div className="spacer" />
//         <div className="spacer" />
//       </Grid>
//     </div>
//   );
// }

// import React from "react"
// import { Grid, Typography, Paper } from "@material-ui/core"
// import { ProfileCard } from "../components/cards"
// import { data } from "../data/profileInfo"

// export default function AboutUsContent() {
//   const [state] = React.useState({
//     profileInfoData: data,
//   });

//   return (
//     <div className="aboutUsPageContainer">
//       <Grid container xs={12} sm={11} md={10} lg={10} className="marginCenter" spacing="3">
   
//         <Grid container sm={12}>
//           <Grid item sm={12}>
//             <Typography className="pageSubTitleSection">
//               Meet our Team
//             </Typography>
//           </Grid>
//         </Grid>

//         <Grid
//               container
//               xs={10}
//               sm={12}
//               md={12}
//               lg={12}
//               spacing={2}
//               className="profileContainer"
//             >
//         {state.profileInfoData.map((item) => {
//           return (
            
//               <Grid item xs={12} sm={6} md={6} lg={4} className="profileInfo">
//               <ProfileCard
//                   name={item.name + " profile image"}
//                   source={item.image}
//                 />
//                 <div className="spacer" />
//                 <div className="spacer" />
//               <Typography className="name">{item.position}</Typography>
//                 <Typography className="name">{item.name}</Typography>
//                 <Typography className="title">{item.title}</Typography>
//                 <div className="spacer" />
//                 <Typography className="email"><a href={"mailto:" + item.email} >{item.email}</a></Typography>
//                   <div className="spacer" />
//                   <div className="spacer" />
//               </Grid>

     
//           )
//         })}
//         </Grid>

    
//       </Grid>
//     </div>
//   );
// }

// import React from "react";
// import { Grid, Typography } from "@material-ui/core"
// import { ProfileCard } from "../components/cards"

// import { data } from "../data/profileInfo"

// export default function AboutUsContent() {
//   const [state] = React.useState({
//     profileInfoData: data,
//   })

//   return (
//     <div className="aboutUsPageContainer">
//       <Grid container sm={12} spacing="3">
    
//           <Grid item sm={12}>
//             <div className="spacer" />
//             <Typography className="pageSubTitleSection">
//               Meet our Team
//             </Typography>
//           </Grid>

//           <Grid
//               container
//               xs={12}
//               sm={12}
//               md={12}
//               lg={12}
//               spacing={3}
//               className="profileContainer"
//             >
//             <Grid item xs={12} sm={12} md={6} lg={6}>
//         {state.profileInfoData.map((item) => {
//           return (
//             <div>
            
//                 {/* <ProfileCard
//                   name={item.name + " profile image"}
//                   source={item.image}
//                 /> */}
//                  <div className="spacer" />
//                  <div className="profileInfo" >
//                     <Typography className="name">{item.name}</Typography>
//                     <Typography className="title">{item.title}</Typography>
//                     <Typography className="email">{item.email}</Typography>
//                 </div>
//                 <div className="spacer" />
          

             

//               <div className="spacer" />
//               <div className="dividerShape" />
//               <div className="spacer" />
        
//             </div>
//           )
          
        
//         })}
//           </Grid>
//         </Grid>
  

//         <div className="spacer" />
//         <div className="spacer" />
//       </Grid>
//     </div>
//   );
// }
import React from "react"
import { Grid, Typography, Paper } from "@material-ui/core"
import { ProfileCard } from "../components/cards"
import { data } from "../data/profileInfo"

export default function AboutUsContent() {
  const [state] = React.useState({
    abousUsData: data,
    profileInfoData: data,
  });

  return (
    <div className="aboutUsPageContainer marginCenter">
      <Grid container xs={12} spacing="3">
 

        <Grid container xs={12}>
      
          <Grid item sm={12}>
            <div className="spacer" />
            <Typography className="pageSubTitleSection marginCenter">
              Meet our Team
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          xs={11}
          sm={11}
          md={12}
          lg={12}
          spacing={3}
          className="profileContainer"
         
        >
        
          {state.profileInfoData.map((item) => {
            return (
              <Grid item xs={12} sm={12} md={6} lg={4} className="profileInfo" style={{background: '#f0f7fb', borderRadius: '20px', padding: '15px', border: '10px solid #fff'}}>
                  <ProfileCard
                    name={item.name + " profile image"}
                    source={item.image}
                  />
                  <div className="spacer" />
                  <Typography className="name">{item.name}</Typography>
                  <Typography className="title">{item.title}</Typography>
                  <Typography className="email">{item.email}</Typography>
                  <div className="spacer" />
                  <Typography variant="body1" className="desc">
                    {item.description}
                  </Typography>
                
                <div className="spacer" />
                <div className="spacer" />
             
               </Grid>
            ) 
          })}
         
         
        </Grid>

        <div className="spacer" />
        <div className="spacer" />
      </Grid>
    </div>
  );
}

