import React from "react";
import { Grid } from "@material-ui/core";

const Footer = () => {
  return (
    <div>
      <Grid container sm={12} className="footer">
        <Grid item sm={12} xs={12}></Grid>
      </Grid>
    </div>
  );
};

export default Footer;
