export const data = [
    {
        category: "operations",
        question: "How much does CloudScend cost?",
        answer: "CloudScend is not a single thing' that can be priced. It is rather a family of solutions. The cost depends on the specific solutions (tools and processes) and levels of effort (resources and scope) that a given customer needs. For example, a given customer may require a multi-cloud solution, source code analysis, and assistance with cloud migration planning."
    },
    {
        category: "marketing",
        question: "How are CloudScend solutions organized?",
        answer: "To simplify messaging, the dozens of solutions are grouped into three major categories of offerings:  ​​​​​​​Explore, Migrate and Operate; and cross-cutting 'service enhancements': Automate, Secure, Optimize and Innovate. Each of these major categories includes one or more solutions that are related to the category."
    },
    {
        category: "technical",
        question: "Are the CloudScend solutions integrated?",
        answer: "Partly and growing. For example, some solutions are accessible via the CloudScend portal. The portal supports single sign-on. The multicloud manager (based on Morpheus) includes integrations with cloud provider services."
    },
    {
        category: "operations",
        question: "Can a customer adopt a part of CloudScend?",
        answer: "Yes, that's the point! Most customers only need a subset of solutions for their needs."
    },
    {
        category: "operations",
        question: "Who in SAIC delivers CloudScend?",
        answer: "The CloudScend solutions are delivered by Digital Innovation Factory operations. For example, the Software operation is responsible for application migrations, whereas the Security operation supports Zero Trust Architectures."
    },
    {
        category: "marketing",
        question: "Do you have any materials that I can give our customers?",
        url: <span>Yes the CloudScend Offering Guide is <a target="_blank" rel="noreferrer" href="https://gov.teams.microsoft.us/_?tenantId=728ac41d-52a3-4e8c-b431-b300a7a7ee8b#/docx/viewer/teams/https:~2F~2Fsaiconline.sharepoint.us~2Fteams~2FPlatformsandCloudTeam~2FShared%20Documents~2FCloudScend%20Marketing%20Messaging~2FOffering%20Guide~2FCloudScend%20Offering%20Guide.docx?threadId=19:gcch:e09ff52999c5400e9d5d36842e96616d@thread.skype&baseUrl=https:~2F~2Fsaiconline.sharepoint.us~2Fteams~2FPlatformsandCloudTeam&fileId=4E165258-BA19-4E4D-B7B1-C7E145A12133&viewerAction=view">HERE</a></span>
    },
    {
        category: "marketing",
        question: "What CloudScend solutions are available?",
        url: <span>They are <a target="_blank" rel="noreferrer" href="https://saiconline.sharepoint.us/teams/CloudScend2/SitePages/CloudScend_Home.aspx " >HERE</a></span>
    }
]