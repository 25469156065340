import React from "react"
import { 
  Typography, 
  Grid,
  ButtonGroup,
  Button
 } from "@material-ui/core"
import { RegCard } from "../components/cards"
import HOME_BANNER from "../assets/images/CLOUD_HOME_BANNER.jpg"
import {
  ANNOUNCEMENT_ICON
} from "../assets/icons/IconList"

import AboutAIJson from "../data/aboutAI.json"



export default function AboutAI() {
  const [state] = React.useState({
    dataValues: AboutAIJson,
  });

  return (
    <div className="aboutAIContainer">
      <Grid container xs={12} className="banner">
        <Grid item xs={12} sm={12}>
          <Typography className="aboutTitle fadePanel">
            SAIC{" "}
            <b>
              <i className="line">
                Platforms <small style={{ color: "#74c5ff" }}>&amp;</small>{" "}
               <span >Cloud</span>
              </i>
            </b>
          </Typography>

          <img src={HOME_BANNER} alt="Home Banner"  />

        </Grid>
        <Grid item xs={12} sm={12}></Grid>
      </Grid>

      <Grid container xs={12} sm={12} md={12} lg={12} className="homeButtons"  >
        <Grid item xs={12}  sm={4} md={4} lg={3} className="marginCenter" >

            <ButtonGroup variant="contained">
              <Button 
              variant="contained"
              target="_blank"
              href="https://saiconline.sharepoint.us/teams/CloudScend2/Shared%20Documents/Forms/AllItems.aspx?id=%2Fteams%2FCloudScend2%2FShared%20Documents%2F101%20%28Business%20Documents%29%2F101%5FPublicSite%5FDocuments%2FCloudScend%20Offering%20Guide%2Epdf&parent=%2Fteams%2FCloudScend2%2FShared%20Documents%2F101%20%28Business%20Documents%29%2F101%5FPublicSite%5FDocuments&p=true&ga=1 "
              >
                Offering Guide 
                </Button>
            
              <Button
                variant="contained"
                target="_blank"
                href="https://saiconline.sharepoint.us/:p:/r/teams/CloudScend2/_layouts/15/guestaccess.aspx?share=EVxPIC6mVtJFlH1Xd1i4n64BS_NVB2FbiV6JPKBpe8swGA"
              >
                Customer Brief
              </Button>
            </ButtonGroup>
             
          </Grid>
      </Grid> 
      <div className="spacer" />
      <div className="dividerShape" />
    </div>
  )
}
