import React from "react";
import { Grid, Typography } from "@material-ui/core";
import AboutUsContent from "./content/aboutUs";
import AwsExportConfig from "./aws-exports";
import { Auth, Amplify } from "aws-amplify";
Amplify.configure(AwsExportConfig());

class AboutUsPage extends React.Component {
  componentDidMount() {
    const getUser = async () => {
      try {
        const authenticatedUser = await Auth.currentAuthenticatedUser();
        console.log("Pages: ", authenticatedUser);
      } catch {
        console.log("The user isn't signed in");

        setTimeout(() => {
          Auth.federatedSignIn({ customProvider: "SAIC" });
        }, 2000);
      }
    };

    getUser();
  }

  render() {
    return (
      <div>
        <main className="contactPageContainer">
          <Grid container xs={12} sm={12} className="fadePanel">
            <Grid item xs={12} sm={12}>
              <Typography variant="h5" className="pageTitleSection">
                ABOUT US
              </Typography>
            </Grid>

            <div className="spacer" />

            <Grid item xs={12} sm={12}>
              <AboutUsContent />
            </Grid>
          </Grid>
        </main>
      </div>
    );
  }
}

export default AboutUsPage;
