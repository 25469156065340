import React from "react"
import { 
   Grid,
   Typography
   } from "@material-ui/core"
import {PartnersCard} from '../components/cards'
import {data} from "../data/partners.js"


export default function InvestmentsContent() {
  const [state] = React.useState({
    dataValues: data,
  });

  return (
    <div className="partnersContainer" >
      <Grid
        container
        xs={12}
        sm={11}
        md={11}
        lg={11}
        spacing={3}
        className="marginCenter"
      >
         <Grid item xs={12}>
          <Typography variant="h5" >
          Strategic Partnerships  
          </Typography>
          <div className="spacer" />
         </Grid>
        {state.dataValues.map((data) => {
          if(data.category === "Strategic"){

          
            return (
                <Grid item xs={6} sm={4} md={4} lg={2}>

                  <PartnersCard 
                    data={data}
                  />

                </Grid>
          
            )
          }
        })}
        <div className="spacer" />
          <div className="dividerShape" />
        <div className="spacer" />

        </Grid>
        
    </div>
  )
}
